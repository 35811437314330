import Image1 from "./images/HelpMySales1.png";

export const helps = [
  {
    message:
      "En este apartado encontrarás todas las subastas que creaste, también puedes ver el estado de cada subasta",
    image: Image1,
    style: "w-[75%]",
  },
];
